import * as React from "react"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

// markup
const IndexPage = () => {
    return (
    <div>
        <Navbar />
        <main>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="Personal Site - Joko Purnomo"  />
                <meta name="keywords" content="personal site,joko purnomo,Joko Purnomo,portfolio" />
                <meta name="author" content="Joko Purnomo" />
                <title>Home | Joko Purnomo</title>
            </Helmet>
            <div className=" bg-white my-16">
                <div className="max-w-4xl mx-auto px-4 sm:px-6">
                    <h1 className="text-3xl font-bold">Halo, Saya Joko Purnomo</h1>
                    <p className="mt-5 text-base md:text-2xl">
                        Situs ini berisi kumpulan project yang pernah saya kerjakan, 
                        dan juga merupakan dokumentasi untuk saya pribadi ketika sedang belajar atau membagikan sesuatu. 
                        Untuk mendapatkan informasi yang sifatnya cepat, 
                        silakan ikuti saya di Instagram&nbsp;
                        <a href="https://www.instagram.com/rokiadhytama/" className="text-blue-600">
                            @jokopurnomo
                        </a>
                        . Jika ingin menghubungi saya, silakan menuju ke halaman&nbsp;
                        <a href="/contact" className="text-blue-600">
                            contact
                        </a>.
                    </p>

                    <a href="/about">
                        <button className="bg-gray-200 text-black text-base hover:bg-gray-300  font-semibold py-2 px-2 mt-6 rounded">
                        Selengkapnya Tentang Joko Purnomo
                        </button>
                    </a>

                    <h1 className="text-3xl font-bold mt-10">Portfolio</h1>
                    
                    <div className="px-2 mt-4 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-5">
                        
                        {/* Project Start */}
                        <div className="rounded overflow-hidden shadow-lg p-4">
                            <StaticImage className="border-2" src="../images/project1.png" alt="Avatar" layout="constrained" loading="eager" width={400} height={200} />
                            <div className="px-6 py-4">
                                <div className="font-bold text-xl mb-2">Al - Qur'an Digital</div>
                                <p className="text-gray-700 text-base">
                                    <a href="https://digital-aq.netlify.app" >Demo</a>
                                </p>
                            </div>

                            <div className="px-6 pt-4 pb-2">
                                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#HTML</span>
                                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#Bootstrap 5</span>
                                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#Javascript</span>
                                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#Jquery</span>
                            </div>

                        </div>
                        {/* Project End */}

                        {/* Project Start */}
                        <div className="rounded overflow-hidden shadow-lg p-4">
                            <StaticImage className="border-2" src="../images/project2.png" alt="Avatar" layout="constrained" loading="eager" width={400} height={200} />
                            <div className="px-6 py-4">
                                <div className="font-bold text-xl mb-2">Aplikasi Tryout</div>
                            </div>

                            <div className="px-6 pt-4 pb-2">
                                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#Laravel</span>
                                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#Bootstrap 4</span>
                                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#Javascript</span>
                                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#Jquery</span>
                            </div>

                        </div>
                        {/* Project End */}

                        {/* Project Start */}
                        <div className="rounded overflow-hidden shadow-lg p-4">
                            <StaticImage className="border-2" src="../images/project3.png" alt="Avatar" layout="constrained" loading="eager" width={400} height={200} />
                            <div className="px-6 py-4">
                                <div className="font-bold text-xl mb-2">Aplikasi Rental Mobil</div>
                            </div>

                            <div className="px-6 pt-4 pb-2">
                                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#Laravel</span>
                                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#Bootstrap 4</span>
                                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#Javascript</span>
                                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#Jquery</span>
                            </div>

                        </div>
                        {/* Project End */}

                        {/* Project Start */}
                        <div className="rounded overflow-hidden shadow-lg p-4">
                            <StaticImage className="border-2" src="../images/project4.png" alt="Avatar" layout="constrained" loading="eager" width={400} height={200} />
                            <div className="px-6 py-4">
                                <div className="font-bold text-xl mb-2">Aplikasi Kasir</div>
                            </div>

                            <div className="px-6 pt-4 pb-2">
                                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#Laravel</span>
                                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#Bootstrap 4</span>
                                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#Javascript</span>
                                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#Jquery</span>
                            </div>

                        </div>
                        {/* Project End */}

                    </div>

                </div>
            </div>

        </main>
        <Footer />
    </div>
    
    )
}

export default IndexPage
